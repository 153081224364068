//@import "../../assets/styles/variables";

/*
body[theme='dark'] {
  .table {
    color: $dark-text-color !important;
    &.table-hover tbody tr:hover {
      color: $dark-text-color !important;
      background-color: $red-background-secondary;
    }
  }
}
*/

td:empty::after{
  content: "\00a0";
}

.table th {
  border: none;
}
