$header-height: 3.85rem;
$header-width: 100%;
$header-margin: 0.325rem;
$header-logo-width: 11rem;
$header-user-card-width: 13rem;
$header-title-margin: 1rem;
$sub-header-height: 2.5rem;

// mobile variables
//$header-logo-width-mobile: 7.5rem;
$header-height-mobile: 2.75rem;
//$header-margin-mobile: 0.25rem;
$header-user-card-width-mobile: 6rem;
$header-title-margin-mobile: 0.75rem;

$sub-header-height-mobile: 2rem;
//$header-width-mobile: 100%;

$body-registros-images-height: 5rem;
$body-registros-images-width: 15rem;

$sidebar-width: 23rem;
$sidebar-width-mobile: 100%;
//$sidebar-width-mobile: calc(100% - 5rem);
//$sidebar-height: calc(100% - #{2 * $header-height});
//$sidebar-height-mobile: calc(100% - #{2 * $header-height-mobile});

$background-primary: #ffffff;
$background-secondary: #14520e;
//$background-menubar: #00491e;
$main-text-color: #008042;
$light-border-color: #ced4da;
$electrans-color2: #239118;
$background-login: #185500;
// #008042 #239118 color electrans

@keyframes openMenuAnimation {
    0%  {left: calc(0rem - $sidebar-width)}
    100%  {left: 0rem}
}

@keyframes closeMenuAnimation {
    0% {left: 0rem}
    100% {left: calc(0rem - $sidebar-width)}
}

@keyframes openMenuAnimationMobile {
    0% {left: calc(0rem - #{$sidebar-width-mobile})}
    100% {left: 0rem}
}

@keyframes closeMenuAnimationMobile {
    0% {left: 0rem}
    100% {left: calc(0rem - 1rem - #{$sidebar-width-mobile})}
}