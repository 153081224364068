@import '../../../assets/styles/variables.scss';

.select .select__control {
  border-color: $light-border-color;
  border-radius: 0.25rem;
}

.select .select__control:hover {
  border-color: $light-border-color;
}

/*
body[theme='dark'] .select {
  .select__control {
    background-color: #122435;
    border-color: $dark-border-color;
    transition: background-color $responsive-animation, border-color $responsive-animation;
  }

  .select__menu {
    background-color: #122435;
  }

  .select__option--is-active,
  .select__option--is-focused{
    background-color: #413032;
  }

  .select__option--is-selected {
    background-color: $dark-background-secondary;
  }

  .select__single-value {
    color: $dark-text-color;
  }
}
*/